@import "cpanel";
@import "hrms-dropdown";

.polaroid {
  border: 1px solid #eeeeee;
  background-color: white;
  box-shadow: 5px 5px 8px 8px #f6f6f6;
}

.polaroid_02 {
  background-color: white;
  box-shadow: 0px 0px 3px 3px #dfdfdf;
}

.hrms-common-card {
    border-radius: 15px;
}

.hr-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: $hrms_radius;
  border-top-right-radius: $hrms_radius;
  border-bottom: 1.5px solid $hrms-color-11;
  background-color: $hrms-color-10;
  font-size: 16px;
  font-weight: 500;
}

.hr-card {
  border-radius: $hrms_radius;
}

.hr-card-body {
  background-color: $hrms-color-10;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  border-bottom-right-radius: $hrms_radius;
  border-bottom-left-radius: $hrms_radius;
}

.hr-card-body-2 {
  background-color: $hrms-color-10;
  min-height: 35vh;
  border-bottom-right-radius: $hrms_radius;
  border-bottom-left-radius: $hrms_radius;
}

.modal-content {
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  border-radius: 15px !important;
}

.leave-mgmt-leave-type-01 {
    color: $hrms-color-07;
}

.leave-mgmt-leave-type-02 {
    color: $hrms-color-06;
}

.leave-mgmt-leave-type-03 {
    color: #701866 ;
}

.hrms-btn-common {
  background-color: $hrms-color-01;
  color: $hrms-color-10;
  border: 0px;
  border-radius: 7px;
}

.hrms-btn-common:hover {
  background-color: $hrms-color-01-hover;
  color: $hrms-color-11;
  border: 0px;
  transform: scale(1.05);
}

.hrms-btn-common:focus {
  background-color: $hrms-color-01-hover;
  color: $hrms-color-11;
  border: 0px;
}

.hrms-btn-common:focus:hover {
  background-color: $hrms-color-01-hover;
  color: $hrms-color-11;
  border: 0px;
}

.hrms-btn-common:active {
  background-color: $hrms-color-01-hover;
  color: $hrms-color-11;
  border: 0px;
}

.hrms-btn-common:active:hover {
  background-color: $hrms-color-01-hover;
  color: $hrms-color-11;
  border: 0px;
}

.hrms-btn-common-blue {
  background-color: $hrms-color-13;
  color: $hrms-color-10;
  border: 0px;
  border-radius: 7px;
}

.hrms-btn-common-blue:hover {
  background-color: $hrms-color-02-hover;
  color: $hrms-color-11;
  border: 0px;
  transform: scale(1.1);
}

.hrms-btn-common-blue:focus {
  background-color: $hrms-color-02-hover;
  color: $hrms-color-11;
  border: 0px;
}

.hrms-btn-common-blue:focus:hover {
  background-color: $hrms-color-02-hover;
  color: $hrms-color-11;
  border: 0px;
}

.hrms-btn-common-blue:active {
  background-color: $hrms-color-02-hover;
  color: $hrms-color-11;
  border: 0px;
}

.hrms-btn-common-blue:active:hover {
  background-color: $hrms-color-02-hover;
  color: $hrms-color-11;
  border: 0px;
}

.hrms-btn-common-edit {
    color: $hrms-color-12;
    border: 0px;
    cursor: pointer;
}

.hrms-btn-common-edit:hover {
    color: $hrms-color-12;
    border: 0px;
    cursor: pointer;
    transform: scale(1.1);
}

.hrms-btn-common-delete {
    color: $hrms-color-01;
    border: 0px;
    cursor: pointer;
}

.hrms-btn-common-delete:hover {
    color: $hrms-color-01;
    border: 0px;
    cursor: pointer;
    transform: scale(1.1);
}

.hrms-hyperlink-btn {
    font-size: 13px;
    color: $hrms-color-01;
    text-decoration: none;
    cursor: pointer;
}

.hrms-hyperlink-btn-b {
    font-size: 13px;
    color: $hrms-color-13;
    text-decoration: underline;
    cursor: pointer;
}

.hrms-hyperlink-btn:hover {
    font-size: 13px;
    color: $hrms-color-01-hover;
    text-decoration: none;
}

.hrms-hyperlink-btn-b:hover {
    font-size: 13px;
    color: $hrms-color-02-hover;
    text-decoration: underline;
    cursor: pointer;
}

.hrms-common-card-header {
    background-color: transparent;
}

.hrms-app-header-text {
    font-size: 14px;
}

.hrms-common-card-footer {
    background-color: transparent;
}

.hrms-input-field-common {
    background-color: #ebedef;
}

.hrms-dropdown-btn {
    background-color: $hrms-color-10;
    border: 1px solid black;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.hrms-dropdown-btn-02 {
    background-color: $hrms-color-10;
    border: 0px;
    border-radius: 5px;
    background-color: transparent;
    color: $hrms-color-05;
}

.hrms-dropdown-btn-02:hover {
    background-color: $hrms-color-10;
    border: 0px;
    border-radius: 5px;
    background-color: transparent;
}

.hrms-dropdown-btn-toggle {
    border: 1px solid black;
    background-color: black;
    color: $hrms-color-10;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.hrms-dropdown-btn:hover {
    background-color: $hrms-color-10;
    border: 1px solid black;
}

.hrms-dropdown-btn-toggle:hover {
    border: 1px solid black;
    background-color: black;
    color: $hrms-color-10;
}

.hr-custom-scroll::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.hr-custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  /* border-radius: 100px; */
}
 
/* Handle */
.hr-custom-scroll::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 100px;
  background-color: #f60f0f;
  background-clip: content-box;
}

/* Handle on hover */
.hr-custom-scroll::-webkit-scrollbar-thumb:hover {
  border: 5px solid transparent;
  border-radius: 100px;
  background-color: #fd6666;
  background-clip: content-box;
}