.scrollable-menu {
  height: auto;
  width: 100%;
  max-height: 300px;
  overflow-x: hidden;
}

.hr-portal-dropdown-btn {
  border: 0px;
  background-color: #ebedef;
  color: #000000;
  min-height: 4vh;
  width: 100%;
  border-radius: 5px;
}

.hr-portal-dropdown-btn:hover {
  border: 0px;
  background-color: #f3cece;
  color: #000000;
  min-height: 4vh;
  width: 100%;
  border-radius: 5px;
}

.hr-portal-dropdown-btn:active {
  border: 0px;
  background-color: #457eb7;
  color: #000000;
  min-height: 4vh;
  width: 100%;
  border-radius: 5px;
}

.hr-portal-dropdown-btn:focus {
  border: 0px;
  background-color: #ebedef;
  color: #000000;
  min-height: 4vh;
  width: 100%;
  border-radius: 5px;
}

.hr-portal-dropdown-btn:focus:hover {
  border: 0px;
  background-color: #f3cece;
  color: #000000;
  min-height: 4vh;
  width: 100%;
  border-radius: 5px;
}

.hr-portal-dropdown-label {
  font-size: 14px
}
