$hrms-color-01: #F60F0F;
$hrms-color-02: #292929;
$hrms-color-03: #646464;
$hrms-color-04: #F1EFEF;
$hrms-color-05: #BEBEBE;
$hrms-color-06: #134F5C;
$hrms-color-07: #E69138;
$hrms-color-08: #1D1D1D;
$hrms-color-09: #f6f6f6;
$hrms-color-10: #ffffff;
$hrms-color-11: #dfdfdf;
$hrms-color-12: #4a4a4a;
$hrms-color-13: #0000ff;

$hrms-color-01-hover: #D10D0D;
$hrms-color-02-hover: #033c99;

$hrms_fs1: 12px;
$hrms_fs2: 17px;
$hrms_fs3: 14px;

$hrms_radius: 15px;
