@import "../../scss/cpanel.scss";

.hrms-account-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.hrms-account-card-image {
    height: 100%;
    border-radius: 0px;
}

.hrms-account-form-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    h5 {
        color: $hrms-color-03;
        font-size: 17px;
    }

    h4 {
        color: $hrms-color-01;
        font-size: 20px;
    }
}

.hrms-account-label {
    font-size: 14px;
}

.hrms-account-custom-col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hrms-account-custom-col-inner-01 {
    font-size: $hrms_fs1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.hrms-account-custom-col-inner-02 {
    font-size: $hrms_fs1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.hrms-account-reset-pass-btn {
    background-color: transparent;
    color: $hrms-color-01;
    font-size: $hrms_fs1;
    text-decoration: none;
}

.hrms-account-card {
    max-width: 70%;
    background-color: $hrms-color-09;
    border-radius: $hrms_radius;
}

.hrms-input-box {
    border-radius: $hrms_radius;
}

.hrms-submit-btn {
    border-radius: $hrms_radius;
    width: 95%;
    color: $hrms-color-10;
}